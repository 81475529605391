import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeCircle,
    placeAngle,
    placeDash,
    placeLabel,
    placeArc
} from '../../../../common/edliy_utils-circle';
const Boxes = {
  box1: function () {
    var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-2, 18, 20, -4],
        keepaspectratio: true, axis:true, ticks:{visible:false},
        grid:true, showCopyright:false, showNavigation:false,
        pan:{enabled:false}, zoom:{enabled:false}});
//
        brd1.options.layer['image']=17;
        brd1.options.layer['text']=16;
        brd1.options.layer['line']=16;
        brd1.options.layer['point']=17;
        brd1.options.layer['glider']=17;
        brd1.options.layer['angle']=18;
        brd1.options.board.minimizeReflow = 'none';
        brd1.options.point.showInfobox =false;
        brd1.options.line.highlight=false;
        brd1.options.image.highlight=false;
    //    brd1.options.text.display='internal';
        //Make the board responsive
        makeResponsive(brd1);
        // Creat titleStyle
        placeTitle(brd1, 'Ohm\'s Law', '(1. Add or remove cells from the circuit. 2. Drag to turn the switch ON. )');
  //  var title1 = brd1.create('text', [8, 15, "<b> Ohm's Law <b>"],{fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(26*brd1.canvasWidth/500.)}},);
  //  var title2 = brd1.create('text', [8, 13.5, '1. Add cells 2. Move the switch'],{fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}},);
    //Tetxs
    //var txt1 = brd1.create('text', [-0.5, 6,  'I(A)'],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(12*brd1.canvasWidth/500.)}},);
    //var txt2 = brd1.create('text', [6, -0.5,  'V(Volts)'],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(12*brd1.canvasWidth/500.)}},);
    placeText(brd1, -0.5, 6, 22, 'I(A)');
    placeText(brd1, 6, -0.5, 22, 'V(Volts)');
    //Circuit
    var width = 8;
    var height = 10;

    var p1 = brd1.create('point', [8,0],{face:'o' , name:'pp', size:2, withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
    var p2 = brd1.create('point', [function(){return p1.X() + width},0],{face:'o' , name:'pp', size:2, withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
    var p3 = brd1.create('point', [function(){return p2.X()} , function(){return p2.Y() + height}],{face:'o' , name:'pp', size:2, withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
    var p4 = brd1.create('point', [function(){return p1.X()} , function(){return p1.Y() + height}],{face:'o' , name:'pp', size:2, withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
    var pint1 = brd1.create('point', [function(){return p4.X()} , function(){return p4.Y() -1}],{face:'o' , name:'pp', size:2, withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
    var pint2 = brd1.create('point', [function(){return p4.X()} , function(){return p4.Y() -3}],{face:'o' , name:'pp', size:2, withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
    var pint3 = brd1.create('point', [function(){return p4.X() + 2} , function(){return p4.Y() -3}],{face:'o' , name:'pp', size:2, withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;

    //var segment1 = brd1.create('segment', [p1, p2], {fixed: true, strokeColor: 'grey'});
    var segment1 = placeLine(brd1, [()=>p1.X(), ()=>p1.Y()], [()=>p2.X(), ()=>p2.Y()], 2, 'grey');
    var segment2 = placeLine(brd1, [()=>p2.X(), ()=>p2.Y()], [()=>p3.X(), ()=>p3.Y()], 2, 'grey');
    var segment3 = placeLine(brd1, [()=>p3.X(), ()=>p3.Y()], [()=>p4.X(), ()=>p4.Y()], 2, 'grey');
    var segment4 =  placeLine(brd1, [()=>p4.X(), ()=>p4.Y()], [()=>pint1.X(), ()=>pint1.Y()], 2, 'grey');
    var segment5 =  placeLine(brd1, [()=>p1.X(), ()=>p1.Y()], [()=>pint2.X(), ()=>pint2.Y()], 2, 'grey');
    var segment6 =  placeLine(brd1, [()=>p4.X(), ()=>p4.Y()], [()=>p1.X(), ()=>p1.Y()], 2, 'grey');
    segment6.setAttribute({visible:false});
    //Resistor
    var resistor = brd1.create('image', ['/assets/resistor.svg', [function(){return p1.X() -1}, function(){return p1.Y() + height/2 -1}], [2,2]], {visible: true, fixed: true});

    //Cells
    var cell1 = brd1.create('image', ['/assets/ui.svg', [15, 4], [2,2]], {visible: true, fixed: true});
    var cell2 = brd1.create('image', ['/assets/ui.svg', [15, 6], [2,2]], {visible: false, fixed: true});
    var cell3 = brd1.create('image', ['/assets/ui.svg', [15, 4], [2,2]], {visible: false, fixed: true});
    var cell4 = brd1.create('image', ['/assets/ui.svg', [15, 7.75], [2,2]], {visible: false, fixed: true});

    //Switch
    var switch1 = brd1.create('image', ['/assets/power.svg', [11, 13], [2,2]], {visible: true, fixed: true});
    //var pSwitch = brd1.create('slider',[[function(){return switch1.X() + 1},13.6],[16,14.3],[0,0,Math.PI/2]], {baseline:{visible:false}, highline:{visible:false},highlight:false, snapDistance:1 ,face:'square', size:function(){return 8*brd1.canvasHeight/800}, strokeWidth: 2, name:'', strokeColor:'black', fillColor: 'grey',  label:{ visible: false, fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
    var pSwitch = 0;
    //Close circuit
    var segCirc = brd1.create('point', [function(){return p1.X() + 2*Math.cos(pSwitch.valueOf()) } , function(){return pint3.Y() + 2*Math.sin(pSwitch.valueOf())} ],{face:'o' , name:'pp', size:2, withLabel:false, strokeColor: 'black', fillColor: 'grey', fixed:false, visible: function(){if (pSwitch.valueOf() == Math.PI/2){return false} else {return true}}, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
    var seg5 = brd1.create('segment', [pint2, segCirc], {fixed: true, strokeColor: 'grey'});
    switch1.on('down', function(){if(pSwitch.valueOf()==0){return pSwitch+=Math.PI/2}else{return pSwitch -=Math.PI/2}});
    //Add and Substract
    var add = brd1.create('image', ['/assets/plus.svg', [12.4, -3], [2.,2.]], {visible: true, fixed: true});
    var substract = brd1.create('image', ['/assets/minuss.svg', [function(){return add.X()-3}, function(){return add.Y()}], [2.,2.]], {visible: true, fixed: true});

    var i =1;
    var maxSteps = 4;

    var volt = 1.5;

    function updateSteps(){

        if (i<=0){
            cell1.setAttribute({visible:false});
            volt=0;

        }
        if(i==1){
            cell1.moveTo([p2.X() -1, 4], 250);

            cell1.setAttribute({visible:true});
            cell2.setAttribute({visible:false});
            volt=1.5;

        }
        if(i==2){
            cell1.moveTo([p2.X() -1, 2], 250);

            cell2.setAttribute({visible:true});
            cell3.setAttribute({visible:false});
            volt=3;
        }
        if(i==3){
            cell1.moveTo([p2.X() -1, 1], 250);
            cell2.moveTo([p2.X() -1, 7], 250);
            cell3.moveTo([p2.X() -1, 4], 250);

            cell3.setAttribute({visible:true});
            cell4.setAttribute({visible:false});
            volt=4.5;
        }

        if(i==4){
            cell1.moveTo([p2.X() -1, 0.25], 250);
            cell2.moveTo([p2.X() -1, 5.25], 250);
            cell3.moveTo([p2.X() -1, 2.75], 250);

            cell4.setAttribute({visible:true});
            volt=6;
        }


    }
    placeText(brd1, 12, 10.5, 22, function(){return 'I = '+ (i*1.5/2).toFixed(2)+' A'});

    add.on('down',function(){
        i = i+1;
        if (i > maxSteps){
            i = maxSteps;
        }
        updateSteps();
    });

    substract.on('down',function(){
        i=i-1;
        if (i < 0){
            i = 0;
        }
        updateSteps();
    });
    var dragMe1 =brd1.create('glider', [8, 0, segment1], {strokeColor: 'black' , fillColor: 'black' , face: '>', size:5, name:'', visible: function(){if (i != 0 && pSwitch.valueOf() == Math.PI/2){return true} else {return false}}, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
    var dragMe2 =brd1.create('glider', [16, 0, segment2], {strokeColor: 'black' , fillColor: 'black' , face: '^', size:5, name:'', visible: function(){if (i != 0 && pSwitch.valueOf() == Math.PI/2){return true} else {return false}}, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
    var dragMe3 =brd1.create('glider', [8, 10, segment3], {strokeColor: 'black' , fillColor: 'black' , face: '<', size:5, name:'', visible: function(){if (i != 0 && pSwitch.valueOf() == Math.PI/2){return true} else {return false}}, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
    var dragMe4 =brd1.create('glider', [16, 0, segment6], {strokeColor: 'black' , fillColor: 'black' , face: 'v', size:5, name:'', visible: function(){if (i != 0 && pSwitch.valueOf() == Math.PI/2){return true} else {return false}}, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
    dragMe1.startAnimation(1, 100, 70);
    dragMe2.startAnimation(1, 100, 70);
    dragMe3.startAnimation(1, 1100, 70);
    dragMe4.startAnimation(1, 100, 70);
    add.setLabel('Add')
    add.label.setAttribute({visible:false, offset:[15, -15], CssStyle:'fontFamily:Oswald', fontSize:16});
    add.on('over', function () {this.label.setAttribute({visible:true});});
    add.on('out', function () {this.label.setAttribute({visible:false});});

    substract.setLabel('Substract')
    substract.label.setAttribute({visible:false, offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:16});
    substract.on('over', function () {this.label.setAttribute({visible:true});});
    substract.on('out', function () {this.label.setAttribute({visible:false});});

    resistor.setLabel('R = 2 &Omega;')
    resistor.label.setAttribute({visible:true, offset:[-30, 40], CssStyle:'fontFamily:Oswald', fontSize:16});
  //  resistor.on('over', function () {this.label.setAttribute({visible:true});});
  //  resistor.on('out', function () {this.label.setAttribute({visible:false});});

    cell1.setLabel('1.5 V')
    cell1.label.setAttribute({visible:()=>i>=1, offset:[75, 40], CssStyle:'fontFamily:Oswald', fontSize:16});
  //  cell1.on('over', function () {this.label.setAttribute({visible:true});});
  //  cell1.on('out', function () {this.label.setAttribute({visible:false});});

    cell2.setLabel('1.5 V')
    cell2.label.setAttribute({visible:()=>i>=2, offset:[75, 40], CssStyle:'fontFamily:Oswald', fontSize:16});
  //  cell2.on('over', function () {this.label.setAttribute({visible:true});});
  //  cell2.on('out', function () {this.label.setAttribute({visible:false});});

    cell3.setLabel('1.5 V')
    cell3.label.setAttribute({visible:()=>i>=3, offset:[75, 40], CssStyle:'fontFamily:Oswald', fontSize:16});
    //cell3.on('over', function () {this.label.setAttribute({visible:true});});
    //cell3.on('out', function () {this.label.setAttribute({visible:false});});

    cell4.setLabel('1.5 V')
   cell4.label.setAttribute({visible:()=>i>=4, offset:[75, 40], CssStyle:'fontFamily:Oswald', fontSize:16});
  //  cell4.on('over', function () {this.label.setAttribute({visible:true});});
  //  cell4.on('out', function () {this.label.setAttribute({visible:false});});

    var Pts=[];
    brd1.create('point', [0., 0.], {name:'', fixed:true, label:{anchorX:'middle', offset:[0, 20],cssStyle:'fontFamily:Oswald;'}, strokeColor:'black', visible:true, fillColor:function(){if(i==0){return 'red'}else{return 'white'}}});
    brd1.create('point', [1.5, 0.75], {name:'', fixed:true, label:{anchorX:'middle', offset:[0, 20],cssStyle:'fontFamily:Oswald;'}, strokeColor:'black',fillColor:function(){if(i==1){return 'red'}else{return 'white'}},  visible:function(){if(i>=1 && pSwitch.valueOf() == Math.PI/2){return true}else{return false}}})
    Pts[0] =brd1.create('point', [3, 1.5], {name:'', fixed:true, label:{anchorX:'middle',offset:[0, 20],cssStyle:'fontFamily:Oswald;'}, strokeColor:'black', fillColor:function(){if(i==2){return 'red'}else{return 'white'}}, visible:function(){if(i>=2 && pSwitch.valueOf() == Math.PI/2){return true}else{return false}}});
    Pts[1] =brd1.create('point', [4.5, 2.25], {name:'', fixed:true, label:{anchorX:'middle',offset:[0, 20],cssStyle:'fontFamily:Oswald;'}, strokeColor:'black',fillColor:function(){if(i==3){return 'red'}else{return 'white'}},visible:function(){if(i>=3 && pSwitch.valueOf() == Math.PI/2){return true}else{return false}}});
    Pts[2] =brd1.create('point', [6, 3.0], {name:'', fixed:true, label:{anchorX:'middle',offset:[0, 20],cssStyle:'fontFamily:Oswald;'},strokeColor:'black',fillColor:function(){if(i==4){return 'red'}else{return 'white'}}, visible:function(){if(i>=4 && pSwitch.valueOf() == Math.PI/2){return true}else{return false}}});
    //Pts[3] =board1.create('point', [5, 6/5], {name:'P_5, V_5', fixed:true,label:{anchorX:'middle',offset:[0, 20],cssStyle:'fontFamily:Oswald;'},strokeColor:'black',visible:function(){if(i>=4){return true}else{return false}}});
    //Pts[4] =board1.create('point', [6, 6/6], {name:'P_6, V_6', fixed:true,label:{anchorX:'middle', offset:[0, 20],cssStyle:'fontFamily:Oswald;'}, strokeColor:'black',visible:function(){if(i>=5){return true}else{return false}}});
    brd1.create('functiongraph',[function(x){return x/2}, 0, function(){return i*1.5}],{strokeWidth:2, visible: function(){if (pSwitch.valueOf() == Math.PI/2){return true} else {return false}}});
    brd1.unsuspendUpdate();
  },
}
export default Boxes;
