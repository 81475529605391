<template>
  <div>
    <h3 ref="ia">
      Ohm's Law
    </h3>
    <p>Ohm's law, named after German physicist and mathematician Georg Simon Ohm, is one of the fundamental principles of electricity. Ohm's law relates the voltage applied across a conducting material to the electric current flowing through it. Ohm, while experimenting with electrochemical cells, found that the electric current flowing through a conducting material is directly proportional to the voltage applied. Mathematically, Ohm's law can be expressed as $$ I \propto V $$ Expressed as equality (by introducing a proportionality constant \(R\)): $$ I = \frac{V}{R}$$ where \(V\) is the voltage loss across the conductor, \(I \) is the electric current through it. \(R\) is the ratio of voltage to current and is known as the electric resistance of the conductor. For certain materials, this ratio \(R\) remains constant over a broad range of values of \(V\) and \(R\). Such conductors are known as Ohmic conductors.</p>
    <h3 ref="ca">
      Implications of Ohm's Law
    </h3>
    <p>Based on Ohm's law, the following conclusions can be deduced about electric circuits.</p>
    <ul style="list-style-type: square;">
      <li>
        <h5>Constant Voltage Circuit</h5>
        For a constant voltage circuit, Ohm's law can be written as $$I = \frac{V}{R}$$ Thus, when the voltage applied to an electric circuit is kept constant (i.e. connected to a source of constant voltage), the electric current decreases with resistance.
      </li>
      <li>
        <h5>Constant Resistance Circuit</h5>
        For a constant resistance circuit, Ohm's law can be written as $$V=IR$$ This means that when the resistance is kept constant, the electric current linearly increases with applied voltage.
      </li>
      <li>
        <h5>Constant Current Circuit</h5>
        For a constant current circuit, Ohm's law can be written as $$V=IR$$ Thus, when the electric current flowing through the circuit is kept constant, the electric voltage required to maintain that current linearly increases with the resistance of the electric circuit.
      </li>
    </ul>
    <h3 ref="playgraph">
      MagicGraph | Ohm's Law
    </h3>
    <p>The goal of this interactive session is to help students:</p>
    <ul style="list-style-type: square;">
      <li>Explain the origin of Ohm's law.</li>
      <li>Calculate voltages, currents, or resistances in an electric circuit with Ohm&rsquo;s law.</li>
    </ul>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'Resistors',
  created: function () {
    // Store mutations
    let title = 'Ohm\'s Law';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'Ohm\'s Law', img:'/assets/number-1.svg', action: () => this.goto('ia')},
      {title: 'Implications of Ohm\'s law', img:'/assets/number-2.svg', action: () => this.goto('ca')},
      {title: 'MagicGraph: Ohm\'s Experiment',img:'/assets/touch.svg', action: () => this.goto('graphical')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newPhys =true;
    this.$store.commit('navigation/replacePhys', newPhys);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Ohm\'s Law',
          titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Ohm\'s law'}
                ]
        }
   },
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
</style>
